<template>
  <div class="flex justify-center bg-[#FEFAF1]">
    <div class="w-full lg:w-[82vw] px-[24px] md:px-6">
      <div class="py-[5rem]">
        <div class="mb-8">
          <h2 class="mb-2 text-[32px] md:text-[60px] text-brand-black leading-[110%] font-semibold">
            Finding your dream job <br class="hidden lg:inline-block" />
            has never been easier
          </h2>
          <p class="text-light-grey/50 text-[18px] md:text-[24px] mb-8">
            We match you to compelling opportunities that lead to your <br class="hidden lg:inline-block" />
            growth.Easily search, apply, and make your next career move <br class="hidden lg:inline-block" />
            with our job board updated weekly.
          </p>

          <Search
            id="myHeader"
            :show-filter-controls="true"
            :filter="filter"
            :filterVars="filterVars"
            :categories="jobCategories"
            @update="setFilterVar"
            @clear="removeFilterItem"
            @on-apply="getJobs"
          />
        </div>
      </div>
    </div>
  </div>

  <!-- <Customers /> -->

  <div class="flex justify-center bg-[#FEFAF5]">
    <div class="w-full lg:w-[82vw] px-[24px] md:px-6">
      <div class="py-[5rem]">
        <div class="mb-16">
          <h2 class="mb-6 text-center text-[32px] md:text-[60px] text-brand-black leading-[110%] font-semibold">Popular Searches</h2>

          <div class="flex gap-4 items-center justify-center flex-wrap">
            <button
              @click="
                setFilterVar($event, 'company_category', '');
                getJobs();
              "
              :class="[
                'inline-block px-6 py-2 text-[16px]',
                !filterKeys['company_category'] ? 'bg-brand-black rounded-3xl text-white font-bold' : 'bg-white rounded-3xl text-brand-black',
              ]"
            >
              All
            </button>
            <button
              @click="
                setFilterVar($event, 'company_category', item.key);
                getJobs();
              "
              :class="[
                'inline-block px-6 py-2 text-[16px] capitalize',
                filterKeys['company_category'] == item.key
                  ? 'bg-brand-black rounded-3xl text-white font-bold'
                  : 'bg-white rounded-3xl text-brand-black',
              ]"
              v-for="item in jobCategories"
              :key="item.key"
            >
              {{ item.value }}
            </button>
          </div>
        </div>

        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 pb-5">
          <job-card v-for="(job, index) in jobs" :key="index" :job="job" />
        </div>

        <div class="text-center mt-8">
          <router-link to="/all-jobs" class="px-8 py-4 bg-brand-black rounded-xl text-white">See more jobs</router-link>
        </div>
      </div>
    </div>
  </div>

  <job-stack />

  <discover-job />

  <loading v-if="isLoading" />
</template>

<script setup>
import { onMounted, ref, computed } from 'vue';
import { useStore } from 'vuex';
import Customers from '@/components/partials/Customers.vue';
import Search from '@/components/Search.vue';
import Loading from '@/components/LoadingOverlay.vue';
import JobCard from '@/components/jobs/JobCard.vue';
import JobStack from '@/components/jobs/JobStack.vue';
import DiscoverJob from '@/components/jobs/DiscoverJob.vue';
import { useMeta } from '@/utils/meta';

const store = useStore();
const jobCategories = ref([]);
const enums = ref([]);
const isLoading = ref(false);

const filterVars = ref([]);
const filter = ref({
  location: '',
  company_category: '',
  work_type: '',
  type: '',
  search: '',
});

const filterKeys = ref({
  location: '',
  company_category: '',
  work_type: '',
  type: '',
  search: '',
});

const setFilterVar = async (event, key, value = '') => {
  if (event.target.type != 'checkbox') {
    filterKeys.value[key] = value ? value : event.target.value;
    filter.value[key] = value ? value : event.target.value;
  } else {
    filterKeys.value[key] = event.target.checked ? event.target.value : '';
    filter.value[key] = event.target.checked && value ? value : '';
  }

  const selected = Object.values(filter.value).filter((val) => val !== '') || [];
  filterVars.value = selected;
};

const removeFilterItem = async (k) => {
  filter.value[k] = '';
  filterKeys.value[k] = '';

  const selected = Object.values(filter.value).filter((val) => val !== '') || [];
  filterVars.value = selected;
  await getJobs();
};

const jobs = computed(() => {
  return store.getters['global/getAllJobs'];
});

const getJobs = async () => {
  const params = Object.entries(filterKeys.value).reduce((a, [k, v]) => (v == '' ? a : ((a[k] = v), a)), {});
  isLoading.value = true;
  try {
    await store.dispatch('global/getAllJobs', { ...params });
  } catch (error) {
  } finally {
    isLoading.value = false;
  }
};

onMounted(async () => {
  try {
    const jobCats = store.getters['global/getJobCategories'];
    jobCategories.value = !Array.isArray(jobCats) || jobCats?.length < 1 ? await store.dispatch('global/getJobCategories') : jobCats;

    const enumVals = store.getters['global/getEnumDetails'];
    enums.value = !Array.isArray(enumVals) || enumVals?.length < 1 ? await store.dispatch('global/getEnums') : enumVals;
    await getJobs();
  } catch (error) {
    //
  }
});

useMeta();
</script>
