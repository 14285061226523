<template>
  <div class="flex justify-center bg-[#FEFAF1]">
    <div class="w-full lg:w-[82vw] px-[24px] md:px-6">
      <div>
        <div>
          <Search :filter="filter" :filterVars="filterVars" :categories="jobCategories" @update="setFilterVar" @on-apply="getJobs" />
        </div>
      </div>
    </div>
  </div>

  <div class="flex justify-center bg-white">
    <div class="w-full lg:w-[82vw] px-[24px] md:px-6">
      <div class="py-8">
        <a href="#" @click.prevent="$router.go(-1)" class="flex items-center">
          <img src="@/assets/icons/chevron-left.svg" class="mr-2" alt="" />
          <span class="text-[#8B8B8B] text-[16px] leading-[26px]">Back to Jobs Page</span>
        </a>
      </div>
    </div>
  </div>

  <div class="flex justify-center bg-[#FEFAF5]">
    <div class="w-full lg:w-[82vw] px-[24px] md:px-6">
      <div class="py-[5rem]">
        <div class="mb-16 job-cards">
          <h2 class="mb-6 text-[32px] md:text-[60px] text-brand-black leading-[110%] font-semibold">Jobs</h2>

          <div class="flex gap-4 items-center flex-wrap">
            <button
              @click="
                setFilterVar($event, 'company_category', '');
                getJobs();
              "
              :class="[
                'inline-block px-6 py-2 text-[16px]',
                !filterKeys['company_category'] ? 'bg-brand-black rounded-3xl text-white font-bold' : 'bg-white rounded-3xl text-brand-black',
              ]"
            >
              All
            </button>
            <button
              @click="
                setFilterVar($event, 'company_category', item.key);
                getJobs();
              "
              :class="[
                'inline-block px-6 py-2 text-[16px] capitalize',
                filterKeys['company_category'] == item.key
                  ? 'bg-brand-black rounded-3xl text-white font-bold'
                  : 'bg-white rounded-3xl text-brand-black',
              ]"
              v-for="item in jobCategories"
              :key="item.key"
            >
              {{ item.value }}
            </button>
          </div>
        </div>

        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 pb-5">
          <job-card v-for="(job, index) in jobs" :key="index" :job="job" />
        </div>

        <!-- Pagination -->
        <paginate v-if="jobs.length" :pagination="pagination" :page="page" @update-page="updatePage" />
      </div>
    </div>
  </div>

  <loading v-if="isLoading" />
</template>

<script setup>
import { onMounted, ref, computed } from 'vue';
import { useStore } from 'vuex';
import Search from '@/components/Search.vue';
import Loading from '@/components/LoadingOverlay.vue';
import JobCard from '@/components/jobs/JobCard.vue';
import Paginate from '@/components/jobs/Paginate.vue';
import { useMeta } from '@/utils/meta';

const store = useStore();
const isLoading = ref(false);
const jobCategories = ref([]);
const enums = ref([]);
const page = ref(1);
const filterVars = ref([]);
const filter = ref({
  location: '',
  company_category: '',
  work_type: '',
  type: '',
  search: '',
});

const filterKeys = ref({
  location: '',
  company_category: '',
  work_type: '',
  type: '',
  search: '',
});

const setFilterVar = async (event, key, value = '') => {
  if (event.target.type != 'checkbox') {
    filterKeys.value[key] = value ? value : event.target.value;
    filter.value[key] = value ? value : event.target.value;
  } else {
    filterKeys.value[key] = event.target.checked ? event.target.value : '';
    filter.value[key] = event.target.checked && value ? value : '';
  }

  const selected = Object.values(filter.value).filter((val) => val !== '') || [];
  filterVars.value = selected;
};

const pagination = computed(() => {
  return store.getters['global/getJobsPayload'];
});

const jobs = computed(() => {
  return store.getters['global/getAllJobs'];
});

const updatePage = (value) => {
  page.value = value;
  getJobs();
  document.querySelector('.job-cards').scrollIntoView();
};

const getJobs = async () => {
  const params = Object.entries(filterKeys.value).reduce((a, [k, v]) => (v == '' ? a : ((a[k] = v), a)), {});
  isLoading.value = true;
  try {
    await store.dispatch('global/getAllJobs', { ...params, page: page.value });
  } catch (error) {
  } finally {
    isLoading.value = false;
  }
};

onMounted(async () => {
  try {
    const jobCats = store.getters['global/getJobCategories'];
    jobCategories.value = !Array.isArray(jobCats) || jobCats?.length < 1 ? await store.dispatch('global/getJobCategories') : jobCats;

    const enumVals = store.getters['global/getEnumDetails'];
    enums.value = !Array.isArray(enumVals) || enumVals?.length < 1 ? await store.dispatch('global/getEnums') : enumVals;
    await getJobs();
  } catch (error) {
    //
  }
});
useMeta();
</script>
